import React from "react";
import foot from './img/footer.svg';
import logoheader from './img/logoheader.svg';

export default function footer(){
    return(
        
            <div className="footer">
                <div className="bodyc">
                    <div style={{textAlign:"center"}}>
                        <img src={logoheader} style={{ width:"248px", margin:"30px"}} />
                        <p style={{marginBottom:"0", color:"white", textAlign:"center", fontSize:"0.8em", }}>¿Qué es gob.mx?</p>  
                        <p style={{marginBottom:"0", color:"white", textAlign:"center", fontSize:"0.8em"}}>Es el portal único de trámites, información y participación ciudadana.</p>
                        <p style={{marginBottom:"5px"}}><a href="tel:5594185288" style={{color:"white", textAlign:"center", fontSize:"0.8em", fontWeight:"600"}}>Tel. (55) 9418-5288</a></p>
                        <p style={{marginBottom:"40px"}}><a href="tel:8004610298" style={{color:"white", textAlign:"center", fontSize:"0.8em", fontWeight:"600"}}>Atención a compradores (800) 4610-298</a></p>
                          
                    </div>
                </div>
                <img src={foot}/>
            </div>
    )
}