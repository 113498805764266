import React from "react";
import sq1 from './img/gob/sq1.png';
import sq2 from './img/gob/sq2.png';
import sq3 from './img/gob/sq3.jpg';
import sq4 from './img/gob/sq4.jpg';
import sq5 from './img/gob/sq5.png';
import sq6 from './img/gob/sq6.jpg';
import it1 from './img/gob/it1.png';
import it2 from './img/gob/it2.png';
import it3 from './img/gob/it3.png';
import it4 from './img/gob/it4.png';
import it5 from './img/gob/it5.png';
import it6 from './img/gob/it6.png';
import it7 from './img/gob/it7.png';
import it8 from './img/gob/it8.png';
import it9 from './img/gob/it9.png';
import it10 from './img/gob/it10.png';
import it11 from './img/gob/it11.png';
import it12 from './img/gob/it12.png';
import ban1 from './img/gob/0.webp';
import ban2 from './img/gob/1.webp';
import ban3 from './img/gob/3.webp';



import Carousel from './carousel'



function inicio(){
    return(
        <div>
            <div style={{height:"70px"}}></div>
            <Carousel/>
            
        <div className="bodyc" style={{textAlign:"center"}}>
            
            <div className="ban_veh">
                <div className="ban_veh_item0" style={{width:"48%"}}>
                    <div class="carousel2 slide" data-ride="carousel2">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                            <img class="d-block w-100" src={ban1} alt="First slide"/>
                            </div>
                            <div class="carousel-item">
                            <img class="d-block w-100" src={ban2} alt="Second slide"/>
                            </div>
                            <div class="carousel-item">
                            <img class="d-block w-100" src={ban3} alt="Third slide"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ban_veh_item" style={{width:"48%"}}>
                    <p className="p_ban"># COMUNICADO</p>
                    <div>
                    <p style={{color:"white", fontWeight:"900"}}>GRAN VENTA DE AUTOMÓVILES DE USO EJECUTIVO Y MAQUINARIA PESADA</p>
                    <p><a href="/vehiculos" class="boton_t" style={{width:"80%"}}>Automóviles</a></p>
                    <p><a href="/maquinaria" class="boton_t" style={{width:"80%"}}>Maquinaria Pesada</a></p>
                    <p style={{color:"white"}}>Descubre las oportunidades del Gobierno Federal</p>
                    </div>
                    
                    
                </div>
            </div>

            <div className="gob_menu">
                <div className="gob_menu_item"><a href="https://programasparaelbienestar.gob.mx/"><img className="gob_menu_img" src={sq1}/></a></div>
                <div className="gob_menu_item"><a href="https://presidente.gob.mx/"><img className="gob_menu_img" src={sq2}/></a></div>
                <div className="gob_menu_item"><a href="https://coronavirus.gob.mx/"><img className="gob_menu_img" src={sq3}/></a></div>
                <div className="gob_menu_item"><a href="https://www.gob.mx/tercerinforme"><img className="gob_menu_img" src={sq4}/></a></div>
                <div className="gob_menu_item"><a href="https://www.gob.mx/proyectosyprogramasprioritarios"><img className="gob_menu_img" src={sq5}/></a></div>
                <div className="gob_menu_item"><a href="https://www.gob.mx/conmemoraciones"><img className="gob_menu_img" src={sq6}/></a></div>

            </div>

            
            <div>
                <div style={{padding:"10px 0 18px 0", textAlign:"left"}}><h3 style={{fontWeight:"400", color:"#706f6f"}}>Gobierno de México</h3>
                <hr className="red" style={{height:"0px", marginTop:"0px"}}></hr></div>
            </div>


            <video data-v-034d2457="" controls="controls" loop="loop" style={{width:"100%"}}><source data-v-034d2457="" src="https://framework-gb.cdn.gob.mx/landing/multimedia/4ta_Transformacion.mp4" type="video/mp4;"/>
                        Your browser does not support the video tag.
                    </video>

                    <div>
            <div style={{padding:"10px 0 18px 0", textAlign:"left"}}><h3 style={{fontWeight:"400", color:"#706f6f"}}>Trámites por categoría</h3>
                <hr className="red" style={{height:"0px", marginTop:"0px"}}></hr></div>
            </div>

            <div className="gob_menu2">
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it1}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>IDENTIDAD, PASAPORTE Y MIGRACIÓN</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Acta de nacimiento</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>CURP</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Cartilla Militar</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Pasaporte</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/identidad" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it2}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>EDUCACIÓN</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Cédula Profesional</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Duplicado de Cédula</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Registro UNADM</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Inscripciones AEFCM</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/educacion" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it3}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>ENERGÍA</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Solicitud de servicio</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Recibo</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Fallas en el servicio</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Precio de gasolina</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/energia" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it4}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>TRABAJO</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Busca empleo</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Consulta de NSS</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Saldo de préstamo ISSTE</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Semanas cotizadas IMSS</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/trabajo" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it5}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>IMPUESTOS Y CONTRIBUCIONES</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>e.firma</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Inscripción al RFC</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Factura electrónica</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Saldos a favor ISR</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/impuestos" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it6}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>SALUD</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Cita médica ISSSTE</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Atención médica IMSS</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Actualiza datos IMSS</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Consulta en el HGM</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/salud" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it7}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>ECONOMÍA</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Registro de marca</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Uso de denominación</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Abre tu empresa</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Crédito FONACOT</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/economia" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it8}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>TERRITORIO Y VIVIENDA</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Estado cuenta FOVISSSTE</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Crédito FOVISSSTE</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Títulos de propiedad</p>
                        <p className="link_hover" style={{color:"white", margin:"0"}}>-</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/territorio" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it9}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>COMUNICACIONES Y TRANSPORTES</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Licencia federal</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Tarjeta de circulación</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Envío de dinero</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Paquetería MEXPOST</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/comunicaciones" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it10}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>PROGRAMAS SOCIALES</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Pensión</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Personas con discapacidad</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Atención médica INAPAM</p>
                        <p className="link_hover" style={{color:"white", margin:"0"}}>-</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/programas" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it11}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>TURISMO</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Guía de turistas</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Registro Nacional de Turismo</p>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>TURISSSTE</p>
                        <p className="link_hover" style={{color:"white", margin:"0"}}>-</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/tramites/turismo" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                <div className="gob_menu2_item">
                    <img style={{width:"4rem"}} src={it12}/>
                    <p className="link_hover" style={{margin:"3px", fontWeight:"900", lineHeight:"1.3"}}>A &gt; Z</p>
                    <div>
                        <p className="link_hover" style={{color:"#706f6f", margin:"0"}}>Otros trámites</p>
                        <p className="link_hover" style={{color:"white", margin:"0"}}>-</p>
                        <p className="link_hover" style={{color:"white", margin:"0"}}>-</p>
                        <p className="link_hover" style={{color:"white", margin:"0"}}>-</p>
                    </div>
                    <div className="gob_menu2_btm"><p style={{margin:"0"}}><a href="https://www.gob.mx/busqueda?utf8=%E2%9C%93" style={{color:"white"}}>Ver todos &gt;</a></p></div>
                </div>
                
                

            </div>


            {/* <div className="directorio">
                <div className="dir-item">
                    <div><img className="dir-img" src={dir1}/></div>
                    <div><h5 ><a className="titulo" href="https://www.gob.mx/indep/estructuras/ernesto-prieto-ortega-254817">Ernesto Prieto Ortega</a></h5><span className="subtitulo">Director General del INDEP</span></div>
                </div>
                <div className="dir-item">
                    <div><img className="dir-img" src={dir2}/></div>
                    <div><h5><a className="titulo" href="https://www.gob.mx/indep/estructuras/luis-barcenas-vazquez">Luis Bárcenas Vázquez</a></h5><span className="subtitulo">Director Corporativo de Administración y Liquidación de Activos</span></div>
                </div>
                <div className="dir-item">
                    <div><img className="dir-img" src={dir3}/></div>
                    <div><h5 ><a className="titulo" href="https://www.gob.mx/indep/estructuras/rodolfo-martinez-figueroa">Rodolfo Martínez Figueroa</a></h5><span className="subtitulo">Director Corporativo de Coordinación Regional</span></div>
                </div>
                <div className="dir-item">
                    <div><img className="dir-img" src={dir4}/></div>
                    <div><h5 ><a className="titulo" href="https://www.gob.mx/indep/estructuras/aldo-jorge-arellano-serrano-259382">Aldo Jorge Arellano Serrano</a></h5><span className="subtitulo">Director Corporativo de Administración y Finanzas</span></div>
                </div>
                <div className="dir-item">
                    <div><img className="dir-img" src={dir5}/></div>
                    <div><h5 ><a className="titulo" href="https://www.gob.mx/indep/estructuras/maricruz-gamez-leon">Maricruz Gámez León</a></h5><span className="subtitulo">Directora Corporativa de Comercialización y Mercadotecnia</span></div>
                </div>
                <div className="dir-item">
                    <div><img className="dir-img" src={dir6}/></div>
                    <div ><h5 ><a className="titulo" href="https://www.gob.mx/indep/estructuras/ignacio-perez-colin">Ignacio Pérez Colín</a></h5><span className="subtitulo">Director Corporativo de Bienes</span></div>
                </div>
                <div className="dir-item">
                    <div><img className="dir-img" src={dir7}/></div>
                    <div ><h5 ><a className="titulo" href="https://www.gob.mx/indep/estructuras/miguel-manuel-ramirez-mandujano-290337">Miguel Manuel Ramírez Mandujano</a></h5><span className="subtitulo">Director Corporativo Jurídico</span></div>
                </div>

            </div>
            <div>
                <div id="blog" style={{padding:"80px 0 18px 0", textAlign:"left"}}><h3 style={{fontWeight:"600"}}>Blog</h3>
                <hr className="red"></hr></div>
            </div>
            <div className="directorio">
                <div className="blog-item">
                    <div><img className="blog-img" src={blog1}/></div>
                    <div><p>16 de diciembre 2020</p></div>
                    <div><h4 className="titulo">Aviso Importante, LyFC en Liquidación</h4></div>
                    <div style={{textAlign:"end"}}>
                        <p><a href="https://www.gob.mx/indep/articulos/aviso-importante-lyfc-en-liquidacion?idiom=es" className="botton-b">continuar leyendo</a></p>
                    </div>
                </div>
                <div className="blog-item">
                    <div><img className="blog-img" src={blog2}/></div>
                    <div><p>30 de noviembre de 2020</p></div>
                    <div><h4 className="titulo">Aviso a las y los Servidores Públicos</h4></div>
                    <div style={{textAlign:"end"}}>
                        <p ><a href="/" className="botton-b">continuar leyendo</a></p>
                    </div>
                </div>
                <div className="blog-item">
                    <div><img className="blog-img" src={blog3}/></div>
                    <div><p>04 de octubre de 2019</p></div>
                    <div><h4 className="titulo">¡Verifica los sitios en los que navegas!</h4></div>
                    <div style={{textAlign:"end"}}>
                        <p ><a href="/" className="botton-b">continuar leyendo</a></p>
                    </div>
                </div>
                <div>
                    
                </div>
            </div>

            
            
            <div id="prensa">
                <div style={{padding:"80px 0 18px 0", textAlign:"left"}}><h3 style={{fontWeight:"600"}}>Prensa</h3>
                <hr className="red"></hr></div>
            </div>
            <div className="directorio">
                    <div className="pre-item">
                        <h4><a className="titulo" href="https://www.gob.mx/indep/prensa/cuidado-con-fraudes-ciberneticos-alerta-instituto-para-devolver-al-pueblo-lo-robado">¡Cuidado con fraudes cibernéticos! alerta Instituto para Devolver al Pueblo lo Robado</a></h4>
                        <p>06 de abril de 2022 <span className="botton-p">Comunicado</span></p>
                        <p className="text-secondary">Con el objetivo de evitar que las personas interesadas en participar en los diferentes eventos comerciales sufran estafas vía electrónica, el Instituto trabaja de manera permanente para bajar de la Web sitios falsos</p>
                    </div>
                    <div className="pre-item">
                        <h4><a className="titulo" href="https://www.gob.mx/indep/prensa/con-subastas-en-linea-y-a-sobre-cerrado-instituto-para-devolver-al-pueblo-lo-robado-recaudo-10-8-mdp">Con Subastas en Línea y a Sobre Cerrado, Instituto para Devolver al Pueblo lo Robado recaudó 10.8 mdp</a></h4>
                        <p>01 de abril de 2022 <span className="botton-p">Comunicado</span></p>
                        <p className="text-secondary">El resultado por tres Subastas en Línea fue de 10 millones 169 mil 762 pesos; en Subasta a Sobre Cerrado se obtuvieron 704 mil pesos</p>
                    </div>
                    <div className="pre-item">
                        <h4><a className="titulo" href="https://www.gob.mx/indep/prensa/loteria-nacional-celebra-con-exito-gran-sorteo-especial-256-298093">Lotería Nacional celebra con éxito Gran Sorteo Especial 256</a></h4>
                        <p>29 de marzo de 2022 <span className="botton-p">Comunicado</span></p>
                        <p className="text-secondary">El Premio Mayor de 10 millones de pesos correspondió al billete No. 0 537 679; el segundo premio de cinco millones de pesos, correspondió al billete No. 0 838 271; el premio asegurado de tres millones de pesos, correspondió al billete No. 2 792 928</p>
                    </div>
                    <div className="pre-item">
                        <h4><a className="titulo" href="https://www.gob.mx/indep/prensa/respuesta-a-nota-periodistica-el-negocio-sucio-de-vender-aviones-del-gobierno">Respuesta a Nota Periodística: "El negocio sucio de vender aviones del gobierno"</a></h4>
                        <p>15 de marzo de 2022 <span className="botton-p">Comunicado</span></p>
                        <p></p>
                    </div>
                    <div className="pre-item">
                        <h4><a className="titulo" href="https://www.gob.mx/indep/prensa/tras-celebrarse-primera-subasta-regional-en-oaxaca-instituto-para-devolver-al-pueblo-lo-robado-obtuvo-27-7-mdp">Tras celebrarse Primera Subasta Regional en Oaxaca, Instituto para Devolver al Pueblo lo Robado obtuvo 27.7 mdp</a></h4>
                        <p>04 de marzo de 2022 <span className="botton-p">Comunicado</span></p>
                        <p className="text-secondary">El Instituto para Devolver al Pueblo lo Robado vuelve a alertar a la sociedad a no dejarse engañar por páginas “no oficiales”, al tiempo de reiterar que el Instituto no tiene intermediarios y mucho menos, gestores</p>
                    </div>
                    <div className="pre-item">
                        <h4><a className="titulo" href="https://www.gob.mx/indep/prensa/instituto-para-devolver-al-pueblo-lo-robado-recauda-31-5-mdp-por-subastas-durante-el-mes-de-febrero">Instituto para Devolver al Pueblo lo Robado recauda 31.5 mdp por subastas durante el mes de febrero</a></h4>
                        <p>27 de febrero de 2022 <span className="botton-p">Comunicado</span></p>
                        <p className="text-secondary">Por las Subastas en Línea se obtuvieron 11 millones 316 mil 657 pesos; en Subasta a Sobre Cerrado 20 millones 259 mil 520 pesos</p>
                    </div>
                    
            </div>
            <h4 id="galeria" style={{textAlign:"end"}}><a className="vermas" href="https://www.gob.mx/indep/archivo/prensa?idiom=es">ver historial</a></h4>
            <div>
                <div style={{padding:"80px 0 18px 0", textAlign:"left"}}><h3 style={{fontWeight:"600"}}>Galería Multimedia</h3>
                <hr className="red"></hr></div>
            </div>
            <div className="galeria">
                <div className="g-item1">
                    <h4 style={{fontSize:"1em", textAlign:"left", fontWeight:"600", borderBottom:"1px solid #dce0e0", paddingBottom:"20px"}}>Álbum de fotos</h4>
                    <h4 style={{fontSize:"1em", textAlign:"left", fontWeight:"600", borderLeft:"10px solid #eeeeee", padding:"10px 5px", margin:"20px 0", color:"gray", textAlign:"center"}}>1a. Subasta Regional Oaxaca, 2022</h4>
                    <div className="gitem11">
                        <div><img className="g-img" src={g1}/></div>
                        <div><img className="g-img" src={g2}/></div>
                        <div><img className="g-img" src={g3}/></div>
                    </div>
                    <div className="gitem11">
                        <div><img className="g-img" src={g4}/></div>
                        <div><img className="g-img" src={g5}/></div>
                        <div><img className="g-img" src={g6}/></div>
                    </div>
                </div>
                <div className="g-item1">
                    <div><img className="blog-img" src={g21}/></div>
                </div>
                <div className="g-item1">
                    <div className="gitem31">
                        <div style={{width:"100%"}}><img className="g-img" src={g31}/></div>
                        <p className="w-100 text-secondary">Primera Subasta Con Sentido Social</p>
                    </div>
                    <div className="gitem31">
                        <div style={{width:"100%"}}><img className="g-img" src={g32}/></div>
                        <p className="w-100 text-secondary">Gran Sorteo Especial No. 248</p>
                    </div>
                    <div className="gitem31">
                        <div style={{width:"100%"}}><img className="g-img" src={g33}/></div>
                        <p className="w-100 text-secondary" >Subasta Aeronaves, AICM</p>
                    </div>
                </div>
                <div>

                </div>
                
                
            </div>

            <h4 style={{textAlign:"end", margin:"30px 0"}}><a className="vermas" href="https://www.gob.mx/indep/archivo/galerias?idiom=es&filter_id=842&filter_origin=archive">ver historial</a></h4>
            <h4 style={{fontSize:"1em", textAlign:"left", fontWeight:"600", borderBottom:"1px solid #dce0e0", paddingBottom:"20px"}}>Videos</h4>
            <div className="galeria">
                <div className="g2-item">
                    <a className="video-img mt-4" href="https://www.gob.mx/indep/videos/acciones-indep-hacemos-mas-que-subastas?idiom=es">
                        <img className="w-100" src="https://img.youtube.com/vi/ZNrjnZUaJ9k/mqdefault.jpg"/>
                    </a>
                    <h4 style={{fontSize:"1em", textAlign:"left", borderLeft:"10px solid #eeeeee", padding:"2px", color:"gray", textAlign:"center", margin:"20px 0"}}>Acciones INDEP ¡Hacemos más que subastas!</h4>
                </div>
                <div className="g2-item">
                    <a className="video-img mt-4" href="https://www.gob.mx/indep/videos/modalidades-de-subastas-indep-258463?idiom=es">
                        <img className="w-100" src="https://img.youtube.com/vi/5D8E12RuRps/mqdefault.jpg"/>
                    </a>
                    <h4 style={{fontSize:"1em", textAlign:"left", borderLeft:"10px solid #eeeeee", padding:"2px", color:"gray", textAlign:"center", margin:"20px 0"}}>Modalidades de Subastas INDEP</h4>
                </div>
                <div className="g2-item">
                    <a className="video-img mt-4" href="https://www.gob.mx/indep/videos/que-no-te-sorprendan-258460?idiom=es">
                        <img className="w-100" src="https://img.youtube.com/vi/jj06wTCW-lk/mqdefault.jpg"/>
                    </a>
                    <h4 id="acciones" style={{fontSize:"1em", textAlign:"left", borderLeft:"10px solid #eeeeee", padding:"2px", color:"gray", textAlign:"center", margin:"20px 0"}}>¡Que no te sorprendan!</h4>
                </div>

            </div>

            <h4  style={{textAlign:"end"}}><a className="vermas" href="https://www.gob.mx/indep/archivo/galerias?idiom=es&filter_id=842&filter_origin=archive">ver historial</a></h4>
            <div>
                <div style={{padding:"80px 0 18px 0", textAlign:"left"}}><h3 style={{fontWeight:"600"}}>Acciones y programas</h3>
                <hr className="red"></hr></div>
            </div>
            <div className="directorio">
                <div className="pre-item">
                        <h4><a href="https://www.gob.mx/indep/acciones-y-programas/mandato-de-lyfc-en-liquidacion" className="titulo">Mandato de LyFC en Liquidación</a></h4>
                        <p className="text-secondary">Información útil para jubilados</p>
                </div>
                <div className="pre-item">
                        <h4><a href="https://www.gob.mx/indep/acciones-y-programas/liberacion-de-garantias" className="titulo">Liberación de Garantías</a></h4>
                        <p className="text-secondary">Para cualquier aclaración podrá comunicarse al Centro de Atención Telefónica de Programa Habitacional 559 183 43 80</p>
                </div>
                <div className="pre-item">
                        <h4><a href="https://www.gob.mx/indep/acciones-y-programas/activos-financieros-administrados-por-el-sae" className="titulo">Activos Financieros administrados por el INDEP</a></h4>
                        <p className="text-secondary">Recuperación de Créditos y Liberación de Garantías.</p>
                </div>
                <div className="pre-item">
                        <h4><a href="https://www.gob.mx/indep/acciones-y-programas/credito-y-ahorro-del-noroeste-sap-cansap" className="titulo">Crédito y Ahorro del Noroeste SAP, CANSAP</a></h4>
                        <p className="text-secondary">Dentro de las atribuciones del INDEP se encuentra la de fungir como visitador, conciliador o síndico de empresas en concurso mercantil.</p>
                </div>
                <div className="pre-item">
                        <h4><a href="https://www.gob.mx/indep/acciones-y-programas/ferrocarriles-nacionales-de-mexico-en-liquidacion" className="titulo">Ferrocarriles Nacionales de México, en liquidación</a></h4>
                        <p className="text-secondary">Encuentra información para ex trabajadores de FNML .</p>
                </div>
                <div className="pre-item">
                        <h4><a href="https://www.gob.mx/indep/acciones-y-programas/oficinas-regionales-del-indep" className="titulo">Coordinaciones Regionales</a></h4>
                        <p id="documentos" className="text-secondary">El INDEP, brinda atención en el interior de la República Mexicana a través de Coordinaciones Regionales, ubicadas en el interior del país.</p>
                </div>

            </div>

            <h4 style={{textAlign:"end", marginBottom:"80px"}}><a className="vermas" href="https://www.gob.mx/indep/archivo/acciones_y_programas?idiom=es&filter_id=847&filter_origin=archive">ver historial</a></h4>
            <div>
                <div style={{padding:"10px 0 18px 0", textAlign:"left"}}><h3 style={{fontWeight:"600"}}>Documentos</h3>
                <hr className="red"></hr></div>
            </div>
            <div className="directorio">
                <div className="pre-item">
                        <h4><a href="" className="titulo">Acuerdo por el que se definen las circunscripciones territoriales de las Coordinaciones Regionales del INDEP</a></h4>
                        <p>lunes, 11 de abril de 2022</p>
                        <p className="text-secondary">Versión integrada del Acuerdo original con sus reformas, publicados en el DOF el 27 de julio de 2020, el 23 de marzo de 2021, el 27 de abril de 2021 y el 11 de abril de 2022, respectivamente.</p>
                </div>
                <div className="pre-item">
                        <h4><a href="" className="titulo">INVITACIÓN A CUANDO MENOS TRES PERSONAS -4</a></h4>
                        <p>miércoles, 06 de abril de 2022</p>
                        <p className="text-secondary">IA-006HKA999-E64-2022 SERVICIO PARA LA CONSERVACIÓN PREVENTIVA DE LOS DOCUMENTOS CARTOGRÁFICOS Y SUS ELEMENTOS DE PROTECCIÓN PARA SU RESGUARDO EN EL ARCHIVO DE CONCENTRACIÓN DEL INDEP</p>
                </div>
                <div className="pre-item">
                        <h4><a href="" className="titulo">CONVOCATORIA PARA CONOCER LA OFERTA DE SERVICIOS DE ABOGADOS Y ABOGADAS POSTULANTES</a></h4>
                        <p>jueves, 31 de marzo de 2022</p>
                        <p className="text-secondary">Se invita a participar en la presente convocatoria, a las personas profesionistas que se encuentren interesadas en colaborar con el INSTITUTO, con experiencia en las materias civil, mercantil, penal, administrativa, fiscal, laboral, agraria y amparo, que deseen conformar la lista de personas Terceras Especializadas a quienes se les podrán asignar asuntos del INSTITUTO para realizar gestiones judiciales y extrajudiciales, ante autoridades jurisdiccionales y/o administrativas en el ámbito federal, local y municipal, durante el período 2022-2025</p>
                </div>
                <div className="pre-item">
                        <h4><a href="" className="titulo">INVITACIÓN A CUANDO MENOS TRES PERSONAS</a></h4>
                        <p>jueves, 31 de marzo de 2022</p>
                        <p className="text-secondary">IA-006HKA999-E63-2022 Servicio de Fletes y Maniobras de Bienes Muebles y Archivo</p>
                </div>
                <div className="pre-item">
                        <h4><a href="" className="titulo">INVITACIÓN A CUANDO MENOS TRES PERSONAS -9</a></h4>
                        <p>viernes, 25 de marzo de 2022</p>
                        <p className="text-secondary">IA-006HKA999-E59-2022 Servicio de Mantenimiento Preventivo y Correctivo con Refacciones a Plantas de Emergencia, Subestaciones Eléctricas, Sistema de Fuerza Ininterrumpida (UPS) y Switch Estático de Transferencia</p>
                </div>
                <div className="pre-item">
                        <h4><a href="" className="titulo">INVITACIÓN A CUANDO MENOS TRES PERSONAS -8</a></h4>
                        <p>viernes, 25 de marzo de 2022</p>
                        <p className="text-secondary">IA-006HKA999-E58-2022 Mantenimiento Preventivo y Correctivo con Refacciones a Elevadores en las instalaciones del INDEP y sus Encargos</p>
                </div>
                

            </div>
            <h4 style={{textAlign:"end", marginBottom:"80px"}}><a className="vermas" href="https://www.gob.mx/indep/archivo/documentos?idiom=es&filter_id=849&filter_origin=archive">ver historial</a></h4> */}



        </div>
        </div>
    )
}

export default inicio;