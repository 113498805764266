import React from "react";
import json from './csvjson.json'
import certificado from './img/gob/SHCP.png'



export default function ventaArticulo(){

    const lote = window.location.pathname.substring(window.location.pathname.length-4);
    
    
    
    for(let i = 0; i < json.length; i++){
        //const found = json[i].find(e => e === id);
        if(json[i].lote == lote){
            const veh = json[i];
            const array = [veh.img0, veh.img1, veh.img2, veh.img3, veh.img4, veh.img5, veh.img6, veh.img7, veh.img8, veh.img9, veh.img10, veh.img11, veh.img12, veh.img13, veh.img14, veh.img15]
            const array2 = [veh.img1, veh.img2, veh.img3, veh.img4, veh.img5, veh.img6, veh.img7, veh.img8, veh.img9, veh.img10, veh.img11, veh.img12, veh.img13, veh.img14]
           console.log(array2)

            
            return(
                <div className="ventaArticulo ">
                    <p className='for_p0' style={{padding:"30px 10px"}}>GOBIERNO DE MÉXICO</p>
                    <div className="bodyc">
                    

                    <div>
                            <div style={{padding:"10px 0", textAlign:"left"}}><h3 style={{fontWeight:"600", fontSize:"1rem"}}>
                                <a href="/vehiculos" style={{color:"black"}}>Autos</a> / {veh.marca} {veh.modelo} {veh.version} </h3>
                            </div>
                            <p style={{fontWeight:"600", marginBottom:"0"}}>$ {veh.price}</p>
                            <p className='sub-list'>{veh.year} • {veh.km} km • {veh.transmision}</p>
        
                    </div>
                    
                    
                    <div className="vA_slider">
                        <div className="vA_slideDer">
                            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                
                                        <div class="carousel-item active">
                                            <img src={array[0]} class="d-block w-100"/>
                                        </div>

                                        {array2.map((list) => {
                                        
                                        if(list != ""){
                                
                                        return(
                                        <div className="carousel-item ">
                                                <img class="d-block w-100" src={list}/>
                                        </div>

                                        )}
                                        })
                                        }
                                    
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>

                        </div>

                        
                        
                        <div className="vA_slideIzq">
                        {array.map((list) => {
                                if(list != "")
                                return(
                                   <div className="vA_mini">
                                        <img className="vA_img" src={list}/>
                                   </div>

                                )

                            })
                            }
                        </div>
                    </div> 
                    <div className="div_boton" >
                        <a  href="tel:8004610298" className="botton_venta" style={{backgroundColor:"rgb(96 124 218)"}}>Llamar</a>
                        
                    </div>
                    <div className="div_boton">
                        <a href={"/cart-"+lote} className="botton_venta" >Me interesa</a>
                        
                    </div>

                    
                    

                    <div className="vA_carac">
                        
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <div className="carac_item">
                                <div className="item1_c"><p className="p_carac">Marca</p><p className="p_carac0">{veh.marca}</p></div>
                                <div className="item1_c"><p className="p_carac">Modelo</p><p className="p_carac0">{veh.modelo}</p></div>
                                <div className="item1_c"><p className="p_carac">Versión</p><p className="p_carac0">{veh.version}</p></div>
                                <div className="item1_c"><p className="p_carac">Año</p><p className="p_carac0">{veh.year}</p></div>
                                <div className="item1_c"><p className="p_carac">Kilometraje</p><p className="p_carac0">{veh.km}</p></div>
                                <div className="item1_c"><p className="p_carac">Transmisión</p><p className="p_carac0">{veh.transmision}</p></div>
                                <div className="item1_c"><p className="p_carac">Tracción</p><p className="p_carac0">{veh.traccion}</p></div>
                                <div className="item1_c"><p className="p_carac">Número de Puertas</p><p className="p_carac0">{veh.puertas}</p></div>
                                
                            </div>

                        </div>
                    </div> 
                    </div>

                    {/* <div className="bodyc" style={{backgroundColor:"#E6E6E6", margin:"0", padding:"10px"}}>
                    <h1 className="vA_carac_p">Pasos para completar tu compra</h1>
                    <div className="vA_pasos" style={{marginBottom:"30px"}}>
                        <div className="flex-row">
                            <div className="circulo">
                                <h2>1</h2>
                            </div>
                            <div className="p_p0">
                                <p  className="p_p1">Carga tus documentos</p>
                                <p className="p_p2">Completa el formulario y carga los documentos pendientes.</p>
                            </div>
                            
                        </div>
                        
                        <div className="flex-row">
                            <div className="circulo">
                                <h2>2</h2>
                            </div>
                            <div className="p_p0">
                                <p className="p_p1">Firma tu contrato</p>
                                <p className="p_p2">Te enviaremos por WhatsApp/Correo un link para consultar y firmar tu contrato.</p>
                            </div>
                            
                        </div>
                        <div className="flex-row">
                            <div className="circulo">
                                <h2>3</h2>
                            </div>
                            <div className="p_p0">
                                <p className="p_p1">Realiza el pago de la unidad</p>
                                <p className="p_p2">Podrás ver los detalles y dudas para realizar tu pago.</p>
                            </div>
                            
                        </div>
                        <div className="flex-row">
                            <div className="circulo">
                                <h2>4</h2>
                            </div>
                            <div className="p_p0">
                                <p className="p_p1">Agenda la entrega</p>
                                <p className="p_p2">Entrega de la unidad hasta en 24 horas después de la firma del contrato. <a style={{color:"gray"}} href="tel:5541604802">(Más información)</a></p>
                                
                            </div>
                            
                        </div>


                    </div>
                    </div> */}


                    {/* <div style={{marginTop:"30px"}}>       
                    <div className="vA_cert bodyc">
                        <div style={{textAlign:"center", margin:"30px 0"}}><img style={{width:"160px"}} src={certificado}/></div>
                        <h1 className="vA_carac_p" style={{margin:"0"}}>Inspeccionado, certificado y reacondicionado</h1>
                        <div className="carac_item" style={{margin:"40px 0"}}>
                            <div className="item1_c">
                                <p style={{padding:"10px", color:"brown", fontWeight:"bold" }}>✔</p>
                                <p style={{padding:"10px"}}>Incluye cobertura por 3 meses y extendible hasta 12 meses</p>
                            </div>
                            <div className="item1_c">
                                <p style={{padding:"10px", color:"brown", fontWeight:"bold"}}>✔</p>
                                <p style={{padding:"10px"}}>Llantas y frenos con más de media vida útil</p>
                            </div>
                            <div className="item1_c">
                                <p style={{padding:"10px", color:"brown", fontWeight:"bold"}}>✔</p>
                                <p style={{padding:"10px"}}>Motor, transmisión, suspensión y tren motriz certificado</p>
                            </div>
                            <div className="item1_c">
                                <p style={{padding:"10px", color:"brown", fontWeight:"bold"}}>✔</p>
                                <p style={{padding:"10px"}}>Libre de adeudos y multas</p>
                            </div>
                            <div className="item1_c">
                                <p style={{padding:"10px", color:"brown", fontWeight:"bold"}}>✔</p>
                                <p style={{padding:"10px"}}>Envío gratuito a toda la República Mexicana. <a style={{color:"gray"}} href="tel:5541604802">(Más información)</a></p>
                            </div>

                        </div>
                    
                    </div>
                    </div> */}

                    
        
                </div>
            )
        }
        
        

    }

    
}