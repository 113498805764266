import React, { useRef, version } from 'react';
import emailjs from '@emailjs/browser';
import json from './csvjson.json';


function Cart(){
  const form = useRef();
  const lote = window.location.pathname.substring(window.location.pathname.length-4);

  

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hys1d6i', 'template_j0yz4so', form.current, 'MWpFdpV73hRCfEVAx')
      .then((result) => {
          console.log(result.text);
          document.getElementById("bodyf").style.display = "none";
          document.getElementById("bodyf2").style.display = "flex";
          
          window.scrollTo(0,0);
          

      }, (error) => {
          document.getElementById("bodyf").style.display = "none";
          document.getElementById("bodyf2").style.display = "flex";
          console.log(error.text);
          window.scrollTo(0,0);
          
      });
  };

  function mostrar(){
    if(document.getElementById("inlineRadio1").checked == true){
        document.getElementById("pfisica").style.display = "unset";
        document.getElementById("pf1").style.display = "flex";
        document.getElementById("pf2").style.display = "none";

        document.getElementById("name").required = true;
        document.getElementById("firstLastName").required = true;
        document.getElementById("secondLastName").required = true;
        document.getElementById("fecha").required = true;
        document.getElementById("rfc").required = true;
        document.getElementById("email").required = true;
        document.getElementById("tel").required = true;
        document.getElementById("dir").required = true;
        document.getElementById("banco").required = true;

        document.getElementById("name2").required = false;
        document.getElementById("email2").required = false;
        document.getElementById("tel2").required = false;
        document.getElementById("dir2").required = false;
        document.getElementById("banco2").required = false;
        

    }
    if(document.getElementById("inlineRadio2").checked == true){
        document.getElementById("pfisica").style.display = "unset";
        document.getElementById("pf1").style.display = "none";
        document.getElementById("pf2").style.display = "flex";

        document.getElementById("name").required = false;
        document.getElementById("firstLastName").required = false;
        document.getElementById("secondLastName").required = false;
        document.getElementById("fecha").required = false;
        document.getElementById("rfc").required = false;
        document.getElementById("email").required = false;
        document.getElementById("tel").required = false;
        document.getElementById("dir").required = false;
        document.getElementById("banco").required = false;

        document.getElementById("name2").required = true;
        document.getElementById("email2").required = true;
        document.getElementById("tel2").required = true;
        document.getElementById("dir2").required = true;
        document.getElementById("banco2").required = true;
        

    }
  }
  
  
  

  for(let i = 0; i < json.length; i++){
    if(json[i].lote == lote){
        const veh = json[i];


        return(
            <div className="formulario" style={{paddingTop:"70px"}}>
                <p className='for_p0' style={{padding:"30px 10px"}}>GOBIERNO DE MÉXICO</p>

                <div className='bodyf' id="bodyf2" style={{display:"none", margin:"50px"}} >
                    <p className='for_p'>Su solicitud de compra ha sido enviada con éxito <a style={{color:"#b38e5d", paddingLeft:"20px"}}>✔</a></p>
                    <p style={{fontWeight:"lighter", fontSize:"15px", textAlign:"justify"}} className='for_p'>¡Enhorabuena! El Gobierno de México agradece su interés.
                    A continuación, le pedimos contar con la documentación necesaria y comunicarse para ser asiginado a un asesor de ventas. 
                    </p>
                    <p style={{fontWeight:"lighter", fontSize:"15px", textAlign:"justify"}} className='for_p'>Documentación requerida:
                    <ul>
                        <li>Identificación Oficial Vigente</li>
                        <li>Comprobante de Domicilio</li>
                    </ul>
                    </p>

                    <p style={{fontWeight:"lighter", fontSize:"15px", textAlign:"justify"}} className='for_p'><a style={{color:"black", fontWeight:"500"}}>Horario de Atención: Lunes - Viernes ( 9:00-18:00 )</a> 
                    </p>

                    <p style={{fontWeight:"lighter", fontSize:"15px", textAlign:"justify"}} className='for_p'>
                        <a style={{color:"black", fontWeight:"500"}} href="tel:5594185288">Teléfono: (55) 9418-5288</a>
                        <a style={{color:"black", fontWeight:"500"}} href="tel:8004610298"> / (800) 4610-298</a>
                       
                    </p>
                    
                    
                    <p className='for_p'><button type="submit" class="botton_venta" style={{width:"100%", marginTop:"50px", backgroundColor:"rgb(96 124 218)"}}><a href='tel:8004610298' style={{color:"white", display:"inline-block", width: "100%"}}>Llamar</a></button></p>
                    <p className='for_p'><button type="submit" class="botton_venta" style={{width:"100%", marginTop:"0"}}><a href='/vehiculos' style={{color:"white", display:"inline-block", width: "100%"}}>Finalizar</a></button></p>
                </div>


                <div className='bodyf' id="bodyf" style={{display:"flex"}}>
                    <p style={{margin:"0"}} className='for_p'>Solicitud de Compra</p>
                    <p style={{fontWeight:"lighter", fontSize:"15px"}} className='for_p'>
                        Pasos para completar la adquisición:
                    </p>
                    <p className='for_p cart_p'>1 - Carga tus documentos</p>
                    <p className="p_p2" style={{textAlign:"center"}}>Completa el formulario y carga los documentos a solicitar.</p>
                    <div className='for_pres' style={{marginBottom:"20px"}}> 
                    <div className='for_foto'>
                        
                        <div>
                            <p style={{margin:"0"}} className='tit-list'>{veh.marca} {veh.modelo} {veh.version}</p>
                            <p className='sub-list'>$ {veh.price}</p>
                        </div>
                        

                    </div>
                    
                </div>
                
                <div className='form_c'>
                    <div class="botton_regimen" style={{width:"30%"}}>
                        <a >Formulario</a>
                    </div>
                    <div class="form-check form-check-inline m-auto reg_1" style={{width:"35%", textAlign:"center"}}>
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" onClick={mostrar}/>
                        <label class="form-check-label" for="inlineRadio1" style={{fontWeight:"lighter"}}>Persona Física</label>
                    </div>
                    <div class="form-check form-check-inline  m-auto reg_1" style={{width:"35%", textAlign:"center"}}>
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" onClick={mostrar}/>
                        <label class="form-check-label" for="inlineRadio2" style={{fontWeight:"lighter"}}>Persona Moral</label>
                    </div>
                </div>


                <div id="pfisica" style={{display:"none"}}>

                
                <form className='form_c' ref={form} onSubmit={sendEmail}>
                   
                <div id="pf1" className="form_c" style={{display:"none"}}>

                <div class="form-group form_item form_item2">
                    <label style={{fontSize:"13px"}}>Primer nombre</label>
                    <input type="text" class="form-control" id="name" aria-describedby="emailHelp" required name="name"/>
                    
                </div>
                <div class="form-group form_item form_item2">
                    <label style={{fontSize:"13px"}}>Segundo nombre</label>
                    <input type="text" class="form-control" id="secName" name="secName"/>
                    <small class="form-text text-muted"></small>
                </div>
                <div class="form-group form_item form_item2">
                    <label style={{fontSize:"13px"}}>Apellido paterno</label>
                    <input type="text" class="form-control" id="firstLastName" name="firstLastName" required/>
                </div>
                <div class="form-group form_item form_item2">
                    <label style={{fontSize:"13px"}}>Apellido materno</label>
                    <input type="text" class="form-control" id="secondLastName" name="secondLastName" required/>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Fecha de nacimiento</label>
                    <input type="date" class="form-control" id="fecha" name="fecha" required/>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>RFC con homoclave</label>
                    <input type="text" class="form-control" id="rfc" name="rfc" required/>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Correo electrónico</label>
                    <input type="email" class="form-control" id="email" name="email" placeholder="ejemplo@correo.com" required/>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Teléfono</label>
                    <input type="text" class="form-control" id="tel" name="tel" required/>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Dirección</label>
                    <input type="text" class="form-control" id="dir" name="dir" required/>
                    <small class="form-text text-muted"></small>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Entidad Bancaria</label>
                        
                    <select id="banco" class="form-control" name="banco" required="true" >
                        <option value="">---</option>
                        <option>BBVA BANCOMER</option>
                        <option>BANAMEX</option>
                        <option>SANTANDER</option>
                        <option>SCOTIABANK</option>
                        <option>BANCO DEL BAJIO</option>
                        <option>BANORTE</option>
                        <option>BANCO INBURSA</option>
                        <option>BANCO AZTECA</option>
                        <option>BANCOPPEL</option>
                        <option>BANCA AFIRME</option>

                        
                    </select>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Comentarios *</label>
                    <input type="text" class="form-control" id="comentarios" name="comentarios" />
                    <small class="form-text text-muted"></small>
                </div>

                </div>

                <div id="pf2" className="form_c" style={{display:"none"}}>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Razón Social</label>
                    <input type="text" class="form-control" id="name2" aria-describedby="emailHelp" required name="name2"/>
                    
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>RFC</label>
                    <input type="text" class="form-control" id="rfc2" name="rfc2"/>
                    <small class="form-text text-muted"></small>
                </div>
                
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Correo electrónico</label>
                    <input type="email" class="form-control" id="email2" name="email2" required placeholder='ejemplo@micorreo.com'/>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Teléfono</label>
                    <input type="text" class="form-control" id="tel2" name="tel2" required/>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Dirección Fiscal</label>
                    <input type="text" class="form-control" id="dir2" name="dir2" required/>
                    <small class="form-text text-muted"></small>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Entidad Bancaria</label>
                        
                    <select id="banco2" class="form-control" name="banco2" required="true" >
                        <option value="">---</option>
                        <option>BBVA BANCOMER</option>
                        <option>BANAMEX</option>
                        <option>SANTANDER</option>
                        <option>SCOTIABANK</option>
                        <option>BANCO DEL BAJIO</option>
                        <option>BANORTE</option>
                        <option>BANCO INBURSA</option>
                        <option>BANCO AZTECA</option>
                        <option>BANCOPPEL</option>
                        <option>BANCA AFIRME</option>

                        
                    </select>
                </div>
                <div class="form-group form_item">
                    <label style={{fontSize:"13px"}}>Comentarios *</label>
                    <input type="text" class="form-control" id="comentarios2" name="comentarios2" />
                    <small class="form-text text-muted"></small>
                </div>


                </div>
            
                <div class="form-group form_item">
                <button type="submit" class="botton_venta" id="enviar" style={{width:"100%", color:"white", marginTop:"22px"}}>Enviar Solicitud</button>
                </div>

                <div class="form-group form_item" style={{display:"none"}}>
                    <input type="text" class="form-control" id="general" name="general" required value={veh.general}/>
                    <input type="text" class="form-control" id="price" name="price" required value={veh.price}/>
                    <input type="text" class="form-control" id="lote" name="lote" required value={veh.lote}/>
                </div>
                
                
                </form>
                </div>








                

                    <p className='for_p cart_p' style={{backgroundColor:"#1c7a4e"}}>2 - Firma tu contrato</p>
                    

                    <p className='for_p cart_p' style={{backgroundColor:"#1c7a4e"}}>3 - Realiza el pago de la unidad</p>
                    

                    <p className='for_p cart_p' style={{backgroundColor:"#1c7a4e"}}>4 - Agenda la entrega</p>
                    

                    


                

                

                <p style={{fontWeight:"lighter", fontSize:"15px", marginTop:"20px", textAlign:"justify"}} className='for_p'>* Los datos aquí proporcionados serán empleados para la elaboración del contrato correspondiente.  </p>

                


          </div>
          
      </div>
        )
    }


  }

};

export default Cart;