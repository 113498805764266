import React, {useState} from 'react';
import json from './csvjson.json'
import logo from './img/indp_logo.jpg'
import certificado from './img/certificado.png';
// import shcp from "./img/hd.png";
import ban2 from './img/gob/ban3.png';
import Carousel from './carousel2'


//console.log(json[0].precio);
export default function Listado(){


// Ordenar por importancia
json.sort((a,b) => {
    if(a.orden < b.orden) {
        return -1
        
    }
    if (a.orden > b.orden){
        return 1
    }
    return 0
})
//   




function mostrarScroll(){
    
    let animado = document.querySelectorAll(".animado");
    let scrollTop = document.documentElement.scrollTop;
    let altura = window.innerHeight;
    

    for (var i=0; i<animado.length; i++){
        let alturaAnimado = animado[i].offsetTop;
        if(alturaAnimado - altura < scrollTop){
            animado[i].style.opacity = 1;
            animado[i].classList.add("mostrarArriba")
            }
        }
    }

    window.onload = mostrarScroll;
    window.addEventListener('scroll', mostrarScroll);

    
    return(
        <div>
        <div className='body-list' style={{paddingTop:"70px"}}>
            <div className='barra'>
            <a  href="/" style={{display:"flex"}}><i class="material-icons" style={{color:"lightgray"}}>home</i></a>
            <a  href="/" style={{margin:"0", color:"gray", fontWeight:"600", paddingLeft:"8px"}}> INICIO</a>
            
            <i class="material-icons" style={{color:"gray"}}>chevron_right</i>
            <a style={{margin:"0", color:"gray", fontWeight:"600"}}>AUTOMÓVILES</a>

                
            </div>
            <Carousel/>
            {/* <div className="logos_list" style={{display:"flex", flexDirection:"row"}}>
                <a href="https://www.gob.mx/shcp"><img className="logoindep" src={ban2}/></a>
            </div> */}

            <div className='list_veh'>
                
            
            {json.map((list) => {

                const { marca, modelo, year, price, img0,} = list;
                if(list.apartado=="1"){
                    return(
                        <div className='body-uni animado '>
                            
                            <a> 
                            <div className='unidad '>
                            
                                <div style={{position:"relative"}} className='img-div'>
                                    
                                    <img className='img-list img-av' src={img0}/>
                                    <div className='apartado'>
                                        <div>APARTADO</div>
                                    </div>
                                </div>
                                <div className='card-list'>
                                    <h1 className='tit-list'>{marca} {list.modelo} {list.version}</h1>
                                    <h1 className='sub-list'>{year} • {list.km} km <script> if({list.especial != ""}){<h1>• {list.especial}</h1>}</script></h1>
                                </div>
                                <div className='pre-list'><h4 style={{fontSize:"1.25rem", fontWeight:"600", margin:"0"}}>$ {price}</h4></div> 
                            </div>
                            </a>
                        </div>
                    )

                }
                if(list.vendido=="1"){
                    return(
                        <div className='body-uni animado '>
                            
                            <a> 
                            <div className='unidad '>
                            
                                <div style={{position:"relative"}} className='img-div'>
                                    
                                    <img className='img-list img-av' src={img0}/>
                                    <div className='vendido'>
                                        <div>VENDIDO</div>
                                    </div>
                                </div>
                                <div className='card-list'>
                                    <h1 className='tit-list'>{marca} {list.modelo} {list.version}</h1>
                                    <h1 className='sub-list'>{year} • {list.km} km <script> if({list.especial != ""}){<h1>• {list.especial}</h1>}</script></h1>
                                </div>
                                <div className='pre-list'><h4 style={{fontSize:"1.25rem", fontWeight:"600", margin:"0"}}>$ {price}</h4></div> 
                            </div>
                            </a>
                        </div>
                    )
                    
                }
                return(
                    <div className='body-uni animado'>
                        <a  href={list.lote}> 
                        <div className='unidad '>
                            <div className='img-div'><img className='img-list' src={img0}/></div>
                            <div className='card-list'>
                                <h1 className='tit-list'>{marca} {list.modelo} {list.version}</h1>
                                <h1 className='sub-list'>{year} • {list.km} km <script> if({list.especial != ""}){<h1>• {list.especial}</h1>}</script></h1>
                            </div>
                            <div className='pre-list'><h4 style={{fontSize:"1.25rem", fontWeight:"600", margin:"0"}}>$ {price}</h4></div> 
                        </div>
                        </a>
                    </div>
                )
            } )}
            
            </div>

        </div>
        
                    {/* <div style={{backgroundColor:"#E6E6E6", marginTop:"30px"}}>       
                    <div className="vA_cert bodyc">
                        <div style={{textAlign:"center", margin:"30px 0"}}><img style={{width:"160px"}} src={certificado}/></div>
                        <h1 className="vA_carac_p" style={{margin:"0"}}>Inspeccionados, certificados y reacondicionados</h1>
                        <div className="carac_item" style={{margin:"40px 0"}}>
                            <div className="item1_c">
                                <p style={{padding:"10px", color:"brown", fontWeight:"bold" }}>✔</p>
                                <p style={{padding:"10px"}}>Incluyen cobertura por 3 meses y extendible hasta 12 meses</p>
                            </div>
                            <div className="item1_c">
                                <p style={{padding:"10px", color:"brown", fontWeight:"bold"}}>✔</p>
                                <p style={{padding:"10px"}}>Llantas y frenos con más de media vida útil</p>
                            </div>
                            <div className="item1_c">
                                <p style={{padding:"10px", color:"brown", fontWeight:"bold"}}>✔</p>
                                <p style={{padding:"10px"}}>Motor, transmisión, suspensión y tren motriz certificado</p>
                            </div>
                            <div className="item1_c">
                                <p style={{padding:"10px", color:"brown", fontWeight:"bold"}}>✔</p>
                                <p style={{padding:"10px"}}>Libres de adeudos y multas</p>
                            </div>
                            <div className="item1_c">
                                <p style={{padding:"10px", color:"brown", fontWeight:"bold"}}>✔</p>
                                <p style={{padding:"10px"}}>Envío gratuito a toda la República Mexicana. <a style={{color:"gray"}} href="tel:5541604802">(Más información)</a></p>
                            </div>

                        </div>
                    
                    </div>
                    </div> */}


                    {/* <div className="bodyc">
                    <h1 className="vA_carac_p">Pasos para completar tu compra</h1>
                    <div className="vA_pasos" style={{marginBottom:"30px"}}>
                        <div className="flex-row">
                            <div className="circulo">
                                <h2>1</h2>
                            </div>
                            <div className="p_p0">
                                <p  className="p_p1">Carga tus documentos</p>
                                <p className="p_p2">Completa el formulario y carga los documentos pendientes.</p>
                            </div>
                            
                        </div>
                        
                        <div className="flex-row">
                            <div className="circulo">
                                <h2>2</h2>
                            </div>
                            <div className="p_p0">
                                <p className="p_p1">Firma tu contrato</p>
                                <p className="p_p2">Te enviaremos por WhatsApp/Correo un link para consultar y firmar tu contrato.</p>
                            </div>
                            
                        </div>
                        <div className="flex-row">
                            <div className="circulo">
                                <h2>3</h2>
                            </div>
                            <div className="p_p0">
                                <p className="p_p1">Realiza el pago de la unidad</p>
                                <p className="p_p2">Podrás ver los detalles y dudas para realizar tu pago.</p>
                            </div>
                            
                        </div>
                        <div className="flex-row">
                            <div className="circulo">
                                <h2>4</h2>
                            </div>
                            <div className="p_p0">
                                <p className="p_p1">Agenda la entrega</p>
                                <p className="p_p2">Entrega de la unidad hasta en 24 horas después de la firma del contrato. <a style={{color:"gray"}} href="tel:5541604802">(Más información)</a></p>
                                
                            </div>
                            
                        </div>


                    </div>
                    </div> */}

        </div>
    )
    
}