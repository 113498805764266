
import { Router, BrowserRouter as Switch, Route, Routes, BrowserRouter } from 'react-router-dom';
import Navbar from './components/navbar.js';
import Body from './components/inicio.js';

import './App.css';
import Footer from './components/footer.js';

//Vehiculos
import Listado from './components/listado.js';
import VentaArticulo from './components/ventaArticulo.js';
import Cart from './components/cart.js';
import Maquinaria from './components/maquinaria.js';
import VentaMaq from './components/ventaMaquinaria.js';
import CartMaq from './components/cartMaq.js';



function App() {
  return (
  
    <BrowserRouter>
      <Navbar/>
      
        <Routes>
            <Route exact path="/" element={<Body />} />
            <Route path="/vehiculos" element={<Listado/>}/>
            <Route exact path="/:id" element={<VentaArticulo/>}/>
            <Route exact path="/cart-:id" element={<Cart/>}/>
            <Route exact path="/maquinaria" element={<Maquinaria/>}/>
            <Route exact path="/MQ:id" element={<VentaMaq/>}/>
            <Route exact path="/mart-:id" element={<CartMaq/>}/>

        </Routes>
        
      <Footer/>
      </BrowserRouter>
    
  );
}

export default App;
