import React from "react";
import ban1 from './img/gob/ban1.png';
import ban2 from './img/gob/ban2.png';
import ban3 from './img/gob/ban3.png';
import ban4 from './img/gob/ban4.png';
import ban5 from './img/gob/ban5.png';
import ban6 from './img/gob/ban6.png';


export default function carousel(){
    return(
        <div className="div_carousel">
            

        
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators" style={{marginBottom:"0"}}>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
            </div>
            <div className="carousel-inner" style={{height:"auto"}}>
                <div className="carousel-item active">
                <img src={ban1} class="d-block w-100 img_carousel" alt="..."/>
                </div>
                <div className="carousel-item">
                <img src={ban2} class="d-block w-100 img_carousel" alt="..."/>
                </div>
                <div className="carousel-item">
                <img src={ban3} class="d-block w-100 img_carousel" alt="..."/>
                </div>
                <div className="carousel-item">
                <img src={ban4} class="d-block w-100 img_carousel" alt="..."/>
                </div>
                <div className="carousel-item">
                <img src={ban5} class="d-block w-100 img_carousel" alt="..."/>
                </div>
                <div className="carousel-item">
                <img src={ban6} class="d-block w-100 img_carousel" alt="..."/>
                </div>
            </div>
            <button className="carousel-control-prev" style={{width:"5%"}} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" style={{width:"5%"}} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
        </div>
        
    )
}