import React from "react";
import logoheader from './img/logoheader.svg';
import lupa from './img/lupa.png'

function navbar(){
    return(
        
        <div className="navbar2">
            <div className="header-grid bodyc">
                
                <div className="header-item">
                    <a className="navbar-a" href="/" title="inicio">
                    <img src={logoheader} style={{width:"150px", height:"50px"}} />
                    </a>
                    
                </div>
                
                
                <div className="ul">
                    <ul className="ulhead">
                        <li className="lihead"><a className="navbar-a" href="https://mivacuna.salud.gob.mx " title="Registro"> Registro para vacunación</a></li>
                        <li className="lihead"><a className="navbar-a" href="https://coronavirus.gob.mx/" title="Coronavirus">Información sobre COVID-19</a></li>
                        <li className="lihead"><a className="navbar-a" href="https://www.gob.mx/tramites/otros" title="Trámites">Trámites </a></li>
                        <li className="lihead"><a className="navbar-a" href="https://www.gob.mx/gobierno" title="Gobierno">Gobierno</a></li>
                        <li className="lihead"><a className="navbar-a" href="https://www.gob.mx/busqueda?utf8=%E2%9C%93" title="Busqyeda"><img src={lupa} /></a></li>
                    </ul>
                    
                    <button class="btn-nav" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    
                    
                </div>
                
                
                
            </div>
            <div class="collapse" id="collapseExample">
                    <div style={{backgroundColor:"#0c231e"}} class="card card-body">
                    <ul className="ulhead-mobile">
                        <li className="lihead"><a className="navbar-a" href="https://mivacuna.salud.gob.mx " title="Registro"> Registro para vacunación</a></li>
                        <li className="lihead"><a className="navbar-a" href="https://coronavirus.gob.mx/" title="Coronavirus">Información sobre COVID-19</a></li>
                        <li className="lihead"><a className="navbar-a" href="https://www.gob.mx/tramites/otros" title="Trámites">Trámites </a></li>
                        <li className="lihead"><a className="navbar-a" href="https://www.gob.mx/gobierno" title="Gobierno">Gobierno</a></li>
                        <li className="lihead"><a className="navbar-a" href="https://www.gob.mx/busqueda?utf8=%E2%9C%93" title="Busqyeda"><img src={lupa} /></a></li>
                    </ul>
                    </div>
                    </div>

            
        </div>
    )
}

export default navbar;